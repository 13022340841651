<template>
  <div>
    <ul class="">
      <li
          v-bind:key="node.id"
          v-for="node in treeData"
      >
        <label>
          <el-checkbox
              v-bind:label="node.id"
              v-bind:name="node.name"
              v-bind:value="node.id"
              v-model="group_projects.roles"
          >{{ node.name }}
          </el-checkbox>
        </label>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
  name: 'RoleTreeModal',
  components: {},
  computed: {
    ...mapState([
      'group_projects',
    ]),
  },
  props: ['treeData', 'groupRolesId'],
  watch: {},
  data() {
    return {
      model: [],
      collapsed: {},
    };
  },
  beforeMount() {
  },
  methods: {},
};
</script>
