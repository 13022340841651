<template>
  <div>
    <collapse-table
        :checked="checkedRoles"
        :data="model"
        :options="options"
        :thead="thead"
        @closeRoleForm="closeRoleForm"
        @removeElement="removeElement"
        @saveRoleForm="saveRoleForm"
        @showAvabileRoles="showAvabileRoles"
    ></collapse-table>
    <div class="box-button align-right p-0">
      <el-button
          @click="openModal()"
          type="primary"
          v-if="!loading"
      >
        {{ $t('system.add') }} {{ $t('system.projects') }}
      </el-button>
    </div>
    <projects-modal
        :dialog-visible="dialogVisible"
        :groupProjectsId="groupProjectsId"
        :projectList="projectList"
        :roleList="roleList"
        v-on:close="close"
    />
  </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import swal from 'sweetalert2';
import ProjectsModal from './ProjectsModal.vue';
import notification from '../../notification/notify';
import CollapseTable from '../../components/ABtable/CollapseTable.vue';

export default {
  name: 'GroupProjects',
  props: ['refreshP'],
  data() {
    return {
      model: [],
      title: 'Group projects',
      source: 'group/' + this.$store.getters.groupId + '/projects',
      create: '/group/create',
      resizable: false,
      adaptive: false,
      draggable: false,
      dialogVisible: false,
      projectList: {},
      roleList: {},
      groupProjectsId: {},
      roleForm: {},
      checkedRoles: [],
      loading: true,
      show: true,
      thead: [
        {
          label: 'system.name',
          key: 'name',
          sort: false,
        },
        {
          label: 'system.rola',
          key: 'rola',
          sort: false,
          type: 'rola',
        },
        {
          label: '',
          key: 'action',
          sort: false,
          type: 'action',
        },
      ],
      options: {
        currentShow: false,
        roleList: {},
      },
    };
  },
  components: {
    'projects-modal': ProjectsModal,
    CollapseTable,
  },
  beforeMount() {
    this.fetchData();
  },
  created() {
  },
  watch: {
    'refreshP'(val, oldValue) {
      if (val) {
        this.getRole();
        this.fetchData();
      }
    },
  },
  methods: {
    close() {
      this.dialogVisible = false;
      this.fetchData();
    },
    openModal() {
      this.getProject();
      this.getRole();
      this.dialogVisible = true;
    },
    getProject() {
      axios.get(`group/${this.$route.params.groupId}/projects`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.groupProjectsId = response.data.model.groupProjectsId;
            this.projectList = response.data.model.projectList.data;
          })
          .catch(error => {
            this.error = error.response.statusText;
          });
    },
    getRole() {
      axios.get(`project/role/${this.$route.params.groupId}`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.roleList = response.data.items;
            this.options.roleList = response.data.items;
          })
          .catch(error => {
            // Request failed.
            this.error = error.response.statusText;
          });
    },
    removeElement: function (element) {
      var vm = this;
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then(function (resp) {
        if (resp.value) {
          axios.post(`member/destroy?project_id=${element.id}&group_id=${vm.$route.params.groupId}`)
              .then(function (response) {
                vm.fetchData();
                notification.notify(
                    vm.$t('notify.success'),
                    response.data.message,
                    'success');
                // swal(
                //   vm.$t('system.remove_success'),
                //   vm.$t('system.remove_message'),
                //   'success'
                // )
              })
              .catch(function (error) {
                notification.notify(
                    vm.$t('notify.error'),
                    error.response.data.error,
                    'error');
              });
        }
      }, function (dismiss) {
        // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
        // if (dismiss === 'cancel') {
        //   swal(
        //     'Cancelled',
        //     vm.$t('system.remove_error'),
        //     'error'
        //   )
        // }
      });
    },
    showAvabileRoles(data, $event) {
      this.show = false;
      this.options.currentShow = data.id;
      this.$set(this.roleForm, data.id, true);
    },
    closeRoleForm(data) {
      this.$set(this.roleForm, data.id, false);
      this.show = true;
      this.options.currentShow = '';
    },
    saveRoleForm(data, checked) {
      var vm = this;
      const config = {headers: {'Content-Type': `application/x-www-form-urlencoded`}};
      axios.put(`updateProject/${data.group_id}/${data.id}`, {
        roles: checked,
      }, config)
          .then(function (response) {
            vm.closeRoleForm(data);
            vm.fetchData();
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    fetchData() {
      this.loading = true;
      var vm = this;
      vm.checkedRoles = [];
      axios.get(`${this.source}`)
          .then(function (response) {
            vm.model = response.data.model.data;
            _.forEach(vm.model, function (projects) {
              _.forEach(projects, function (groupRoles) {
                _.forEach(groupRoles.roles, function (role) {
                  vm.checkedRoles.push(
                      projects.id + '-' + role.id,
                  );
                });
              });
            });
            vm.loading = false;
            vm.show = true;
            vm.current = '';
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
      vm.$emit('setRefresh', true);
    },
  },
};
</script>
