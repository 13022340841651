<template>
  <div>
    <ul class="">
      <li
          v-bind:key="node.id"
          v-for="node in treeData"
      >
        <label>
          <el-checkbox
              :disabled="isCheck(node)"
              :label="node.id"
              v-model="group_projects.projects"
          >
            {{ node.name }}
          </el-checkbox>
        </label>
        <template v-if="hasChild(node)">
          <project-tree-modal
              :groupProjectsId="groupProjectsId"
              :treeData="node.children"
          >
          </project-tree-modal>
        </template>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapState} from 'vuex';

export default {
  name: 'ProjectTreeModal',
  components: {},
  computed: {
    ...mapState([
      'group_projects',
    ]),
  },
  props: ['treeData', 'groupProjectsId'],
  data() {
    return {
      collapsed: {},
    };
  },
  beforeMount() {
  },
  mounted() {
  },
  methods: {
    hasChild: function (node) {
      if (typeof (node.children) !== 'undefined' && node.children.length > 0) {
        return true;
      }
      return false;
    },
    isRoot: function (node) {
      if (node.root) {
        return true;
      }
      return false;
    },
    collapseTree: function (node) {
      if (this.collapsed[node.id]) {
        Vue.set(this.collapsed, node.id, false);
      } else {
        Vue.set(this.collapsed, node.id, true);
      }
    },
    isCollapsed: function (node) {
      if (node.root == null) {
        return true;
      }
      if (this.collapsed[node.id]) {
        return true;
      }
      return false;
    },
    isCheck(node) {
      if (this.$props.groupProjectsId.indexOf(node.id) > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>
