<template>
  <el-dialog
      :lock-scroll="true"
      :title="$t('system.add') + ' ' +$t('system.projects')"
      :visible.sync="dialogVisible"
      @close="hide(true)"
      width="90%"
  >
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col">
          <el-card
              shadow="never"
              v-if="projectList"
          >
            <div
                class="clearfix"
                slot="header"
            >
              <span>{{ $t('system.projects') }}</span>
            </div>
            <div class="projectModal__item">
              <project-tree-modal
                  :groupProjectsId="groupProjectsId"
                  :treeData="projectList"
              ></project-tree-modal>
            </div>
            <small
                class="help-block"
                v-if="backend_errors.projects"
            >
              {{ backend_errors.projects[0] }}
            </small>
          </el-card>
        </div>
        <div class="col">
          <el-card
              shadow="never"
              v-if="roleList"
          >
            <div
                class="clearfix"
                slot="header"
            >
              <span>{{ $t('system.roles') }}</span>
            </div>
            <div class="projectModal__item">
              <role-tree-modal :treeData="roleList"></role-tree-modal>
            </div>
            <small
                class="help-block"
                v-if="backend_errors.roles"
            >
              {{ backend_errors.roles[0] }}
            </small>
          </el-card>
        </div>
      </div>
    </div>
    <span
        class="dialog-footer"
        slot="footer"
    >
        <el-button
            :loading="loading"
            type="primary"
            v-on:click.native="save"
        >{{ $t('system.save') }}</el-button>
        <el-button @click="hide(true)">
          {{ $t('system.cancel') }}
        </el-button>
      </span>
  </el-dialog>
</template>

<script>
import axios from 'axios';
import ProjectTreeModal from './ProjectTreeModal.vue';
import RoleTreeModal from './RoleTreeModal.vue';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

// TODO: refactor all component
export default {
  name: 'ProjectsModal',
  props: ['projectList', 'groupProjectsId', 'roleList', 'dialogVisible'],
  components: {
    ProjectTreeModal,
    RoleTreeModal,
  },
  data() {
    return {
      projects: {},
      roles: {},
      db_name: {},
      backend_errors: {},
      option: {},
      title: 'Create',
      resource: 'group',
      store: `group/${this.$route.params.groupId}`,
      redirect: '/',
      loading: false,
      method: 'post',
    };
  },
  beforeMount() {
    this.$store.commit('CLEAR_GROUP_PROJECTS');
  },
  methods: {
    async save() {
      this.loading = true;
      let vm = this;
      vm.backend_errors = {};
      let formData = new FormData();
      for (var property in this.$store.getters.group_projects) {
        if (this.$store.getters.group_projects[property].length === 0) {
          this.loading = false;
          return notification.notify(
              this.$t('notify.warning'),
              this.$t(`system.chose_${property}`),
              'warning');
        }
        formData.append(property, JSON.stringify(this.$store.getters.group_projects[property]));
      }
      axios[this.method](this.store, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(function (response) {
        vm.$emit('close');
      })
          .catch(function (error) {
            let dataErrors = error.response.data.errors;
            let errors = Global.getErrors(vm, dataErrors, 2);

            vm.$store.commit('SET_STICKY_ARRAY', {
              array: errors,
            });
            vm.backend_errors = dataErrors ?? {};
          })
          .finally(() => this.loading = false);
    },
    hide(emit) {
      if (emit) {
        this.$store.commit('CLEAR_GROUP_PROJECTS');
        this.$emit('close');
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.projectModal__item {
  max-height: 400px;
  overflow: auto;
}
</style>
