<template>
  <div class="view-container container-default custom_tabs--block">
    <div class="view">
      <div class="view-body p-0">
        <el-tabs
            @tab-click="handleClick"
            class="custom_tabs"
            type="card"
            v-model="activeName"
        >
          <el-tab-pane>
            <span slot="label">
              <v-icon>fas fa-edit</v-icon>
              {{ $t('system.general') }}
            </span>
            <el-form>
              <el-form-item
                  :label="$t('system.group_name')"
                  required
              >
                <small
                    class="help-block float-right"
                    v-if="db_name.name"
                >
                  {{ db_name.name.length }}/{{ maxlength }}
                </small>
                <small
                    class="help-block float-right"
                    v-else
                >
                  0/{{ maxlength }}
                </small>
                <el-input
                    :class="backend_errors.name && !db_name.name ? 'validate-error' : ''"
                    :maxlength="maxlength"
                    :placeholder="$t('system.group_name')"
                    name="name"
                    type="text"
                    v-model="db_name.name"
                ></el-input>
                <small
                    class="help-block"
                    v-if="backend_errors.name && !checkinputRequired('name')"
                >
                  <i class="fa fa-times-circle-o"></i>
                  {{ backend_errors.name[0] }}
                </small>
                <!--
                Forms with only one input behave strangelySolutions:
                    a) remove the <form>
                    b) add a hidden input (that you don’t have to use for anything)
                -->
                <input hidden>
                <div class="box-button align-right pb-0 pr-0">
                  <el-button
                      :loading="loading"
                      type="primary"
                      v-on:click="save"
                  >{{ $t('system.save') }}
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
            <!--<el-input class="mt-3" v-model="db_name.name"></el-input>-->
            <!--<div class="box-button align-right pb-0 pr-0">-->
            <!--<el-button type="primary" :loading="loading" v-on:click="save">{{$t('system.save')}}</el-button>-->
            <!--</div>-->
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <v-icon> fas fa-users</v-icon>
              {{ $t('system.users') }}
            </span>
            <group-users
                :refreshU="refreshU"
                @setRefresh="setRefresh(false)"
            ></group-users>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <v-icon>fas fa-project-diagram</v-icon>
              {{ $t('system.projects') }}
            </span>
            <group-projects
                :refreshP="refreshP"
                @setRefresh="setRefresh(false)"
            ></group-projects>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import Global from '../../helpers/global';
import GroupUsers from './Users.vue';
import GroupProjects from './Projects.vue';
import notification from '../../notification/notify';

let vm = null;
export default {
  name: 'GroupShow',
  components: {
    'group-users': GroupUsers,
    'group-projects': GroupProjects,
  },
  data() {
    return {
      db_name: {},
      resource: 'group',
      backend_errors: {},
      maxlength: 30,
      store: `group/${this.$route.params.groupId}`,
      redirect: '',
      checkedPermissions: [],
      permissions: [],
      activeName: '0',
      loading: false,
      refreshU: false,
      refreshP: false,
    };
  },
  beforeMount() {
    vm = this;
    this.fetchData();
  },
  watch: {
    '$route': 'fetchData',
  },
  methods: {
    checkinputRequired(name) {
      if (_.includes(this.inputRequiredEmpty, name)) return true;
    },
    getinputRequired() {
      let vm = this;
      let inputs = document.querySelectorAll('.is-required .el-input__inner');
      vm.inputRequiredEmpty = [];
      _.forEach(inputs, function (item) {
        if (item.value.length === 0) vm.inputRequiredEmpty.push(item.name);
      });
    },
    updateCheckedPermissions(e) {
      this.checkedPermissions = e;
    },
    remove() {
      var vm = this;
      axios.delete(`${this.resource}/${this.$route.params.groupId}`)
          .then(function (response) {
            if (response.data.deleted) {
              vm.$router.push(vm.redirect);
            }
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.message,
                'error');
          });
    },
    fetchData() {
      var vm = this;
      axios.get(`${this.resource}/${this.$route.params.groupId}`)
          .then(function (response) {
            Vue.set(vm.$data, 'db_name', response.data.db_name);
            Vue.set(vm.$data, 'permissions', response.data.permissions);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    save() {
      vm.getinputRequired();
      this.loading = true;
      this.method = 'post';

      Global.save(this, vm);
    },
    handleClick(tab, event) {
      switch (this.activeName) {
        case '1': {
          this.refreshU = true;
          break;
        }
        case '2': {
          this.refreshP = true;
          break;
        }
      }
    },
    setRefresh(status) {
      this.refreshU = false;
      this.refreshP = false;
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-tab-pane {
  padding: 20px;
}
</style>
