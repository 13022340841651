<template>
  <div>
    <div
        class="table-responsive"
        v-if="thead && thead.length > 0"
    >
      <table class="table">
        <thead>
        <tr>
          <th
              :key="item.key"
              class="table--th-view"
              v-for="item in thead"
          >
                    <span class="d-flex align-items-start">
                        {{ $t(item.label) }}
                    </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            :key="key"
            v-for="(row, key) in data"
        >
          <td
              :key="key + '_' + item.key"
              class="table--tbody"
              v-for="item in thead"
          >
            <div
                :class="item.deleteWrap ? 'deleteWrap' : ''"
                style="text-align: left"
            >
              <!--action type BEGIN-->
              <template v-if="item.type === 'action'">
                <span v-if="options.currentShow === row.id">
                  <el-button
                      @click="saveRoleForm(row)"
                      size="mini"
                      type="primary"
                  >{{ $t('system.save') }}
                  </el-button>
                  <el-button
                      @click="closeRoleForm(row)"
                      size="mini"
                  >{{ $t('system.cancel') }}
                  </el-button>
                </span>
                <span v-else>
                  <el-button
                      @click="showAvabileRoles(row)"
                      size="mini"
                      type="info"
                      v-permission="options.permissions ? options.permissions.edit : ''"
                  >
                    {{ $t('system.edit') }}
                  </el-button>
                  <el-button
                      @click.prevent="removeElement(row)"
                      size="mini"
                      type="danger"
                      v-if="options.disabledRoles ? showButtonRemove(row) : true"
                      v-permission="options.permissions ? options.permissions.delete : ''"
                  >
                    {{ $t('system.delete') }}
                  </el-button>
                </span>
              </template>
              <!--action type END-->
              <!--action type BEGIN-->
              <template v-if="item.type === 'collapse'">
                <el-collapse
                    accordion
                    class="el-collapse-member"
                    v-if="row.group"
                >
                  <el-collapse-item>
                    <template slot="title">
                      <i class="header-icon fa fa-users"></i>
                      <span style="margin-left: 10px">{{ row.name }}</span>
                    </template>
                    <p
                        class="pr-2 pl-2"
                        style="text-align:left;"
                        v-bind:key="'group' + user.id"
                        v-for="user in row.users"
                    >
                      {{ user.name }}</p>
                  </el-collapse-item>
                </el-collapse>
                <span
                    style="margin-left: 10px"
                    v-else
                >
                  {{ row.name }}
                </span>
              </template>
              <!--action type END-->
              <!--role type BEGIN-->
              <template v-else-if="item.type === 'rola'">
                <template v-if="options.currentShow !== row.id && row.group_roles">
                  <template v-for="r in row.group_roles.roles">
                    <span
                        class="d-block pt-1 pb-1"
                        v-bind:key="r.id"
                    >{{ r.name }}</span>
                  </template>
                </template>
                <template v-if="options.currentShow !== row.id && row.roles">
                  <template v-for="r in row.roles">
                    <span
                        class="d-block pt-1 pb-1"
                        v-bind:key="r.id"
                    >{{ r.name }}</span>
                  </template>
                </template>
                <span
                    class="edit-modal"
                    v-if="options.currentShow === row.id"
                >
                  <div class="mt-2">
                    <div
                        style="text-align:left;"
                        v-bind:key="role.id"
                        v-for="role in options.roleList"
                    >
                      <el-checkbox
                          v-bind:key="row.id+'-'+role.id"
                          v-bind:label="row.id+'-'+role.id"
                          v-bind:value="role.name"
                          v-if="!row.type"
                          v-model="checkedRoles"
                      > {{ role.name }}
                      </el-checkbox>
                      <el-checkbox
                          v-bind:disabled="isDisabled(row.type +'-'+row.id+'-'+role.id)"
                          v-bind:label="row.type +'-'+row.id+'-'+role.id"
                          v-else
                          v-model="checkedRoles"
                      >
                        {{ role.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </span>
              </template>
              <!--role type END-->
              <template v-else>
                <span>
                  {{ row[item.key] ? row[item.key] : '' }}
                </span>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <span class="no-date-title m-3">{{ $t('system.no_selected_column') }}</span>
    </div>
    <div v-if="data && data.length === 0 && thead && thead.length > 0">
      <span class="no-date-title m-3">{{ $t('system.no_data') }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CollapseTable',
  props: ['data', 'thead', 'options', 'checked'],
  data() {
    return {
      checkedRoles: [],
    };
  },
  beforeMount() {
    let vm = this;
    if (typeof vm.checked !== 'undefined' || vm.checked) {
      vm.checkedRoles = vm.checked;
    }
  },
  methods: {
    showAvabileRoles(data) {
      this.$emit('showAvabileRoles', data);
    },
    removeElement(data) {
      this.$emit('removeElement', data);
    },
    closeRoleForm(data) {
      this.$emit('closeRoleForm', data);
    },
    saveRoleForm(data) {
      this.$emit('saveRoleForm', data, this.checkedRoles);
    },
    isDisabled(key) {
      this.$emit('isDisabled', key);
    },
    showButtonRemove(element) {
      let vm = this;
      if (vm.options.disabledRoles) {
        var disabledUserRole = vm.options.disabledRoles.filter(function (e) {
          return e.startsWith('u-' + element.id + '-');
        });
        if (element && disabledUserRole) {
          if (element.roles) {
            if (Object.keys(element.roles).length === disabledUserRole.length) {
              return false;
            }
          }
        }
      }
      return true;
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import './../../static/css/table';
</style>
