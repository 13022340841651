<template>
  <el-dialog
      :title="$t('system.add_users')"
      :visible.sync="dialogVisible"
      @close="hide(true)"
      width="80%"
  >
    <div class="form">
      <el-card
          class="box-card"
          shadow="never"
      >
        <el-input
            :placeholder="$t('system.search_user')"
            type="text"
            v-model="search"
        >
          <template slot="prepend">{{ $t('system.search_user') }}</template>
        </el-input>
      </el-card>
      <el-card
          class="box-card"
          shadow="never"
          v-if="userList"
      >
        <ul
            class="list"
            v-if="userList"
        >
          <li
              :key="user.id"
              class="text item"
              v-for="user in filterUserList"
          >
            <el-checkbox
                v-bind:label="user.id"
                v-bind:value="user.id"
                v-model="checkedUser"
            >
              {{ user.name }}
            </el-checkbox>
            <small
                class="text-danger"
                v-if="backend_errors.users"
            >
              {{ backend_errors.users[0] }}
            </small>
          </li>
        </ul>
      </el-card>
    </div>
    <span
        class="dialog-footer"
        slot="footer"
    >
        <el-button
            :loading="loading"
            type="primary"
            v-on:click.native="save"
        >{{ $t('system.save') }}</el-button>
        <el-button @click="hide(true)">
          {{ $t('system.cancel') }}
        </el-button>
      </span>
  </el-dialog>
</template>

<script>
import Global from '../../helpers/global';

export default {
  name: 'UsersModal',
  props: {
    userList: {
      type: Array,
      default: () => [],
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      checkedUser: [],
      roles: {},
      db_name: {},
      backend_errors: {},
      option: {},
      title: 'Create',
      resource: 'group',
      store: `group/${this.$route.params.groupId}`,
      redirect: '',
      loading: false,
      method: 'post',
    };
  },
  computed: {
    filterUserList() {
      const users = [...this.userList];
      return users.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      }).filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  methods: {
    save() {
      let vm = this;
      vm.loading = true;
      vm.method = 'post';
      vm.db_name.users = this.checkedUser;
      vm.db_name.id = this.$route.params.groupId;

      Global.save(this, vm);
    },
    hide(emit) {
      if (emit) {
        this.checkedUser = [];
        this.$emit('close');
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.list {
  column-count: 3;
  -moz-column-count: 3;
  -webkit-column-count: 3;

  li {
    display: inline-block;
    width: 100%;

    label {
      margin: 0;
    }
  }
}
</style>
