<template>
  <div>
    <el-table
        :data="model"
        border
        class="mt-3"
        fit
        highlight-current-row
        style="width: 100%;"
        v-loading="loading"
    >
      <el-table-column
          :label="$t('system.users')"
      >
        <template slot-scope="scope">
          <v-icon
              name="users"
              v-if="scope.row.group"
          ></v-icon>
          <span style="margin-left: 10px">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('system.actions')"
          width="100"
      >
        <template slot-scope="scope">
          <el-button
              @click="removeElement(scope.row)"
              size="mini"
              type="danger"
          >
            {{ $t('system.delete') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="box-button align-right pb-0 pr-0">
      <el-button
          @click="openModal"
          type="primary"
          v-if="!loading"
      >
        {{ $t('system.add_members') }}
      </el-button>
    </div>
    <!--<ab-table :data="model" :source="source" :thead="thead"></ab-table>-->
    <users-modal
        :dialog-visible="dialogVisible"
        :userList="userList"
        @close="close"
    />
  </div>
</template>
<script>
import axios from 'axios';
import swal from 'sweetalert2';
import UsersModal from './UsersModal.vue';
import notification from '../../notification/notify';
// import ABTable from '../../components/ABtable/index.vue';

export default {
  name: 'GroupUsers',
  props: ['refreshU'],
  data() {
    return {
      model: [],
      title: 'Group users',
      source: '/group/' + this.$store.getters.groupId + '/users',
      resource: 'group',
      create: '/group/create',
      resizable: false,
      adaptive: false,
      draggable: false,
      userList: {},
      roleList: {},
      checkedRoles: [],
      loading: true,
      dialogVisible: false,
      thead: [
        {
          label: this.$t('system.user'),
          key: 'name',
          sort: false,
        },
        {
          label: '',
          key: 'action',
          sort: false,
          type: 'action',
        },
      ],
    };
  },
  components: {
    'users-modal': UsersModal,
    // 'ab-table': ABTable
  },
  watch: {
    'refreshU'(val, oldValue) {
      if (val) {
        this.fetchData();
      }
    },
  },
  methods: {
    show(modal, resizable, adaptive, draggable) {
      this.resizable = resizable;
      this.adaptive = adaptive;
      this.draggable = draggable;
      // this.$nextTick(() => {
      //     this.$modal.show(modal)
      // })
    },
    close() {
      this.dialogVisible = false;
      this.fetchData();
    },
    openModal() {
      this.getUser();
      // this.getRole();
      this.dialogVisible = true;
    },
    getUser() {
      axios.get(`group/${this.$route.params.groupId}/getUser`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.userList = response.data.items;
          })
          .catch(error => {
            // Request failed.
            this.error = error.response.statusText;
          });
    },
    getRole() {
      axios.get(`group/${this.$route.params.groupId}/getRole`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.roleList = response.data.items;
          })
          .catch(error => {
            this.error = error.response.statusText;
          });
    },
    fetchData() {
      this.loading = true;
      var vm = this;
      vm.checkedRoles = [];
      axios.get(`${this.source}`)
          .then(function (response) {
            vm.model = response.data.model;
            vm.loading = false;
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
      vm.$emit('setRefresh', true);
    },
    removeElement: function (element) {
      var vm = this;
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then(function (resp) {
        if (resp.value) {
          axios.delete(`group/${vm.$route.params.groupId}/users/${element.id}`)
              .then(function (response) {
                vm.fetchData();
                notification.notify(
                    vm.$t('notify.success'),
                    vm.$t('notify.deleted'),
                    'success');
              })
              .catch(function (error) {
                notification.notify(
                    vm.$t('notify.error'),
                    error.response.data.error,
                    'error');
              });
        }
      }, function (dismiss) {
      });
    },
  },
};
</script>
